import { Box, Container, Grid, Stack, Divider } from "@mui/material";
import React from "react";
import {
  HeaderDescription,
  LargeText,
  LightText,
  NormalText,
  SmallText,
} from "../../Components/Typograyphy";
import eye from "../../Assets/images/About.Hero.Section.png";
import aboutEvents from "../../Assets/images/aboutevents.png";
import aboutEventsii from "../../Assets/images/About.Hero.Section.Events.png";

import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { SectionItem } from "../../Components/SectionItem";
import { sectionBDetails, teamData } from "../../Lib/data";
import { TextIconButton } from "../../Components/Buttons";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import { AboutBox } from "./about.styles";

const AboutPage = () => {
  const navigate = useNavigate();

  const seeFullTeam = () => {
    navigate("/about/our-team");
  };
  return (
    <>
      <Navbar />
      <AboutBox>
        <header className="header__section">
          <div className="container h-100">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <h5 className="title">
                  Enabling people and businesses everywhere envision and attain
                  compelling outcomes.
                </h5>
              </div>
              <div className="col-lg-6">
                <img
                  src={eye}
                  className="img-fluid hero__image"
                  alt="About cognitive labs"
                />
              </div>
            </div>
          </div>
        </header>

        <section className="metric__section">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-6">
                <div className="image__holder">
                  <img src={aboutEventsii} alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 d-flex align-items-center">
                <div className="text__container">
                  <h4 className="title">
                    We solve for an impactful customer experience, employing
                    cloud and AI technology to provide predictive analytics,
                    automated workflows and streamlined processes.
                  </h4>

                  <div className="row">
                    <div className="col-md-6 analysis__container">
                      <h5>$6 billion addressable markets</h5>
                      <p>
                        Bringing financial inclusion to the EMEA markets
                        undergoing exponential growth- wit
                      </p>
                    </div>
                    <div className="col-md-6 analysis__container">
                      <h5>200 million+ online payment transactions</h5>
                      <p>
                        Our payment solutions provide repeat purchases and
                        loyalty to businesses and customers
                      </p>
                    </div>
                    <div className="col-md-6 analysis__container mb-md-0">
                      <h5>Promoting collaboration in the learning sectors</h5>
                      <p>
                        Our solutions improve collaboration and drive up quality
                        education and meaningful impact to 5 Million students
                        and professionals
                      </p>
                    </div>
                    <div className="col-md-6 analysis__container mb-md-0">
                      <h5>Financial Inclusion</h5>
                      <p>
                        Introducing different forms of payments to reduce
                        friction in payment processes. Helping businesses gain
                        insights from their customers through loyalty programs
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="culture__section">
          <div className="container">
            <h4 className="title">OUR CULTURE</h4>

            <p className="details">
              We are <span>&nbsp;energized</span> about solving big problems;
              <span>&nbsp;constantly innovating</span> and leveraging on our
              <span>&nbsp;diversity—</span>maximizing the power of what makes us
              different to provide solutions.
            </p>
          </div>
        </section>

        <Container maxWidth="xl">
      
          {/* <Divider sx={{ marginTop: { md: "250px", xs: "100px" } }} /> */}
          {/* </Container>
      <Container maxWidth="xl"> */}
          {/* <Box
          sx={{
            my: { md: "64px", xs: "32px" },
            px: { md: "40px", xs: "20px" },
          }}>
          <SmallText cggray>OUR LEADERSHIP</SmallText>
          <Grid container spacing={2} direction="row" display="flex">
            {teamData.map((team, index) => (
              <Grid item xs={6} md={3} key={team.id}>
                <Box sx={{ width: { md: "75%" } }}>
                  <SmallText cgblack mb="10px">
                    {team.name}
                  </SmallText>
                  <LightText>{team.role}</LightText>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2} direction="row" display="flex">
            {teamData.map((team, index) => (
              <Grid item xs={6} md={3} key={team.id}>
                <Box sx={{ width: { md: "75%" } }}>
                  <SmallText cgblack mb="10px">
                    {team.name}
                  </SmallText>
                  <LightText>{team.role}</LightText>
                </Box>
              </Grid>
            ))}
          </Grid>
          <TextIconButton Icon={EastIcon} onClick={seeFullTeam}>
            See our full team
          </TextIconButton>
        </Box> */}
        </Container>
      </AboutBox>
      <Footer />
    </>
  );
};

export default AboutPage;
