import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Navbar from "../../Components/Navbar";
import storiesHero from "../../Assets/images/heroiv.png";
import { East, West } from "@mui/icons-material";
import {
  GrayOutlinedButton,
  SolidButton,
  TextIconButton,
} from "../../Components/Buttons";
import { featuredStories, otherStories } from "../../Lib/data";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";

const StoriesPage = () => {
  const navigate = useNavigate();

  const seeAllStories = () => {
    navigate("/stories/all-stories");
  };
  return (
    <>
      <Navbar />
      <Container maxWidth="xl">
        <Box
          sx={{
            padding: { md: "50px", xs: "25px" },
          }}>
          <Typography
            sx={{
              color: "#006AFF",
              fontWeight: 300,
              fontSize: { md: 38, xs: "24px" },
              textAlign: { md: "center", xs: "left" },
              width: { md: "60%", xs: "100%" },
              mx: "auto",
              mb: "20px",
            }}>
            Changing the history of institutions from a woman’s point of view
            and managizalizng stuffs
          </Typography>

          <Box
            sx={{
              width: { md: "80%", xs: "100%" },
              mx: "auto",
            }}>
            <img src={storiesHero} alt="" width="100%" />
            <div style={{ textAlign: "center", mx: "auto" }}>
              <TextIconButton Icon={East}>Read the story</TextIconButton>
            </div>
          </Box>

          <Box
            sx={{
              my: "100px",
              mx: "auto",
              width: "100%",
            }}>
            <Typography
              sx={{
                fontFamily: "Archivo",
                fontWeight: "300",
                fontSize: { md: "32px", xs: "28px" },
                color: "#424342",
                mx: "auto",
                width: "100%",
                textAlign: { md: "center", xs: "left" },
                mb: { md: "45px", xs: "25px" },
                lineHeight: { md: "38px", xs: "30px" },
              }}>
              Featured stories about{" "}
              <span style={{ color: "#006AFF" }}>Students</span>
            </Typography>
            <Grid container item xs={12} spacing={4}>
              {featuredStories.map((item, index) => (
                <Grid item md={3} key={item.id}>
                  <div>
                    <img
                      style={{
                        width: "100%",
                        marginBottom: "15px",
                      }}
                      src={item.image}
                      alt=""
                    />
                  </div>
                  <div style={{ width: "80%" }}>
                    <Typography
                      sx={{
                        fontSize: "21px",
                        color: "#000000",
                        fontFamily: "Archivo",
                        fontWeight: 300,
                        mb: "20px",
                      }}>
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#606060",
                        fontFamily: "Hind",
                        fontWeight: 400,
                        mb: "35px",
                      }}>
                      {item.description}
                    </Typography>
                  </div>
                  <GrayOutlinedButton>Learn more</GrayOutlinedButton>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box sx={{ py: "75px" }}>
            <Typography
              sx={{
                fontFamily: "Archivo",
                fontWeight: "300",
                fontSize: { md: "32px", xs: "28px" },
                color: "#424342",
                mx: "auto",
                width: "100%",
                textAlign: { md: "center", xs: "left" },
                mb: { md: "45px", xs: "25px" },
                lineHeight: { md: "34px", xs: "30px" },
              }}>
              Explore other stories
            </Typography>
            <Grid container item xs={12} spacing={{ md: 8, xs: 3 }}>
              {otherStories.map((item, index) => (
                <Grid item md={6} key={item.id}>
                  <Typography
                    sx={{
                      fontSize: { md: "24px", xs: "16px" },
                      color: "#424342",
                      fontFamily: "Archivo",
                      fontWeight: 300,
                      mb: { md: "20px", xs: "12px" },
                      textAlign: { md: "center", xs: "left" },
                      width: "75%",
                      mx: { md: "auto" },
                    }}>
                    {item.title}
                  </Typography>
                  <div>
                    <img
                      style={{
                        width: "95%",
                        marginBottom: "15px",
                      }}
                      src={item.image}
                      alt=""
                    />
                  </div>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#424342",
                      fontFamily: "Hind",
                      fontWeight: 400,
                      mb: { md: "35px", xs: "15px" },
                      textAlign: "center",
                      width: "75%",
                      mx: "auto",
                    }}>
                    {item.length}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <div style={{ textAlign: "center", mx: "auto" }}>
              <SolidButton colored onClick={seeAllStories}>
                View all stories
              </SolidButton>
            </div>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default StoriesPage;
