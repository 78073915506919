import { Typography } from "@mui/material";
import React from "react";

export const CustomText = ({ children }) => {
  return <Typography sx={{}}>{children}</Typography>;
};

export const LargeText = ({ children, colored, size, mb }) => {
  return (
    <Typography
      sx={{
        fontSize: size ? { md: size, xs: "32px" } : { md: "48px", xs: "32px" },
        color: colored
          ? (theme) => theme.palette.primary.main
          : (theme) => theme.palette.cognitiveGray.main,
        fontWeight: 400,
        fontFamily: "Archivo",
        lineHeight: size ? size : { md: "52.22px", xs: "38px" },
        marginBottom: mb ? mb : { md: "20px", xs: "12px" },
      }}>
      {children}
    </Typography>
  );
};

export const LightText = ({ children, color, size }) => {
  return (
    <Typography
      sx={{
        fontSize: { md: size, xs: "14px" },
        color: color ? color : "black",
        fontWeight: 100,
        fontFamily: "Hind",
        marginBottom: "20px",
      }}>
      {children}
    </Typography>
  );
};

export const NormalText = ({ children, color, size, mb }) => {
  return (
    <Typography
      sx={{
        fontSize: { md: size, xs: "18px" },
        color: color ? color : "black",
        fontWeight: 400,
        fontFamily: "Hind",
        marginBottom: "8px",
      }}>
      {children}
    </Typography>
  );
};

export const SmallText = ({ children, cgblack, cggray, cgbrown, mb }) => {
  return (
    <Typography
      sx={{
        fontSize: { md: "21px", xs: "15px" },
        color: cgblack
          ? (theme) => theme.palette.cognitiveBlack.main
          : cgbrown
          ? (theme) => theme.palette.cognitiveBrown.main
          : (theme) => theme.palette.cognitiveGray.main,
        fontWeight: 300,
        fontFamily: "Hind",
        lineHeight: "28px",
        marginBottom: { md: mb ? mb : "20px", xs: "2px" },
      }}>
      {children}
    </Typography>
  );
};

export const HeaderDescription = ({ children, sx }) => {
  return (
    <Typography
      sx={{
        fontSize: "21px",
        color: (theme) => theme.palette.cognitiveGray.main,
        fontWeight: 500,
        fontFamily: "Hind",
        lineHeight: "22.85px",
        marginBottom: "16px",
        sx,
      }}>
      {children}
    </Typography>
  );
};

export const FooterLinkHeader = ({ children }) => {
  return (
    <Typography
      sx={{
        fontWeight: 400,
        fontSize: { md: "20px", xs: "16px" },
        fontFamily: "Hind",
        color: "#006AFF",
      }}>
      {children}
    </Typography>
  );
};

export const FooterSocialHeader = ({ children }) => {
  return (
    <Typography
      sx={{
        fontWeight: 400,
        fontSize: { md: "20px", xs: "16px" },
        fontFamily: "Hind",
        color: "#454242",
        marginBottom: "10px",
      }}>
      {children}
    </Typography>
  );
};
