import { styled } from "@mui/material";
import hero from "../../Assets/images/header-bg.jpeg";

export const HomeBox = styled("section")(({ theme }) => ({
  "& .header__section": {
    padding: "3.5rem 0 5rem",
    position: "relative",

    "& .title": {
      fontSize: "2.65rem",
      color: "#011531",
      fontWeight: 500,
      textAlign: "center",
      margin: "0 auto 3rem",
      width: "75%",
    },

    "& .hero__image": {
      width: "100%",
      borderRadius: "12px",
      height: "720px",
      background: `url(${hero})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      // objectFit: "cover",
    },

    "& .details": {
      color: "#424342",
      fontSize: "1.15rem",
      fontWeight: 300,
      margin: "2rem auto",
      width: "80%",
      textAlign: "center",
    },

    "@media (max-width:1199.9px)": {
      padding: "4rem 0",
      "& .title": {
        fontSize: "2.45rem",
        width: "90%",
      },

      "& .details": {
        fontSize: "1.05rem",
        width: "90%",
      },
    },

    "@media (max-width:991.9px)": {
      padding: "3rem 0",
      "& .title": {
        fontSize: "2.25rem",
        width: "95%",
        marginBottom: "2rem",
      },

      "& .details": {
        fontSize: "1rem",
        width: "95%",
      },
    },

    "@media (max-width:767.9px)": {
      padding: "2.5rem 0",
      "& .title": {
        fontSize: "2rem",
        width: "98%",
        marginBottom: "2rem",
      },

      "& .details": {
        width: "100%",
      },
    },
  },

  "& .solution__space": {
    padding: "5rem 0",

    "& .text__holder": {
      textAlign: "center",
      width: "70%",
      margin: "0 auto 2.25rem",
      padding: 0,

      "& .sub__title": {
        color: "#011531",
        fontSize: "1.15rem",
        marginBottom: 0,
      },

      "& .title": {
        color: "#006AFF",
        fontSize: "3rem",
        fontWeight: 300,
      },
    },

    "& .solution__card": {
      "& img": {
        borderRadius: "10px",
        objectFit: "cover",
        height: "500px",
      },
    },

    "& .active__card": {
      "& img": {
        height: "700px",
      },
    },

    "& .details": {
      color: "#424342",
      fontSize: "1.15rem",
      fontWeight: 300,
      margin: "2rem auto",
      width: "80%",
      textAlign: "center",
    },

    "@media (max-width:1199.9px)": {
      padding: "4rem 0",

      "& .text__holder": {
        width: "80%",

        "& .sub__title": {
          fontSize: "1.05rem",
        },

        "& .title": {
          fontSize: "2.65rem",
        },
      },

      "& .solution__card": {
        "& img": {
          height: "350px",
        },
      },

      "& .active__card": {
        "& img": {
          height: "500px",
        },
      },

      "& .details": {
        fontSize: "1.05rem",
        width: "90%",
      },
    },

    "@media (max-width:991.9px)": {
      padding: "3rem 0",

      "& .text__holder": {
        width: "90%",

        "& .sub__title": {
          fontSize: "1rem",
        },

        "& .title": {
          fontSize: "2.25rem",
        },
      },

      "& .solution__card": {
        "& img": {
          height: "270px",
        },
      },

      "& .active__card": {
        "& img": {
          height: "400px",
        },
      },

      "& .details": {
        fontSize: "1rem",
        width: "95%",
      },
    },

    "@media (max-width:767.9px)": {
      padding: "2.5rem 0",

      "& .text__holder": {
        width: "100%",

        "& .sub__title": {
          fontSize: "0.9rem",
        },

        "& .title": {
          fontSize: "2rem",
        },
      },

      "& .solution__card, .active__card": {
        marginBottom: "1.5rem",

        "& img": {
          height: "350px",
        },
      },

      "& .details": {
        fontSize: "0.9rem",
        width: "100%",
      },
    },
  },

  "& .market__section": {
    padding: "5rem 0",

    "& .text__holder": {
      width: "95%",
      margin: "0 0 2.25rem",
      padding: 0,

      "& .title": {
        color: "#011531",
        fontSize: "2.55rem",
        fontWeight: 600,
        marginBottom: "1.5rem",
      },

      "& .details": {
        color: "#424342",
        fontSize: "1.15rem",
        fontWeight: 300,
        marginBottom: "2.5rem",
      },

      "& .market__list": {
        listStyle: "none",
        paddingLeft: 0,

        "& .item": {
          borderBottom: "1px solid rgba(132, 127, 127, 0.30)",
          marginBottom: "1rem",

          "&:last-child": {
            marginBottom: 0,
            borderBottom: "none",
          },

          "& .name": {
            fontSize: "1.5rem",
            fontWeight: 500,
            color: "#011531",
            marginBottom: 0,
          },

          "& .value": {
            fontSize: "1.05rem",
            fontWeight: 300,
            color: "#424342",
            margin: "1rem 0",
          },
        },
      },
    },

    "& .market__img": {
      height: "100%",
      display: "flex",
      flexDirection: "column",

      "& img": {
        objectFit: "cover",
        height: "100%",
        borderRadius: "10px",
        maxHeight: "auto",
      },
    },

    "@media (max-width:1199.9px)": {
      padding: "4rem 0",

      "& .text__holder": {
        width: "100%",
        margin: "0 0 2rem",

        "& .title": {
          fontSize: "2.25rem",
          marginBottom: "1.25rem",
        },

        "& .details": {
          fontSize: "1.05rem",
          marginBottom: "2.25rem",
        },

        "& .market__list": {
          "& .item": {
            "& .name": {
              fontSize: "1.15rem",
            },

            "& .value": {
              fontSize: "0.9rem",
            },
          },
        },
      },

      "& .market__img": {
        height: "100%",
        "& img": {
          objectFit: "cover",
          height: "100%",
          borderRadius: "10px",
        },
      },
    },

    "@media (max-width:767.9px)": {
      padding: "2.5rem 0",

      "& .text__holder": {
        margin: "0 0 1.5rem",

        "& .title": {
          fontSize: "2rem",
          marginBottom: "1rem",
        },

        "& .details": {
          fontSize: "1rem",
          marginBottom: "2rem",
        },

        "& .market__list": {
          "& .item": {
            "& .name": {
              fontSize: "1rem",
            },

            "& .value": {
              fontSize: "0.875rem",
            },
          },
        },
      },

      "& .market__img": {
        height: "100%",
        "& img": {
          objectFit: "cover",
          height: "100%",
          borderRadius: "10px",
        },
      },
    },
  },

  "& .culture__section": {
    padding: "5rem 0 7rem",

    "& .title": {
      fontWeight: 300,
      fontSize: "1.25rem",
      textTransform: "uppercase",
      marginBottom: "2rem",
      color: "#424342",
    },

    "& .details": {
      fontWeight: 100,
      fontSize: "2.25rem",
      color: "#000000",
      // width: "95%",

      "& span": {
        fontWeight: 400,
        color: "#006AFF",
      },
    },

    "@media (max-width:991.9px)": {
      padding: "2.5rem 0 5rem",

      "& .title": {
        fontSize: "1.15rem",
      },

      "& .details": {
        fontSize: "1.25rem",
        width: "100%",
      },
    },

    "@media (max-width:767.9px)": {
      padding: "2.5rem 0 5rem",

      "& .title": {
        fontSize: "1.05rem",
      },

      "& .details": {
        fontSize: "1.15rem",
        width: "100%",
      },
    },
  },
}));
