import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { TextIconButton } from "../../Components/Buttons";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import { fullTeamList } from "../../Lib/data";
import Footer from "../../Components/Footer";

const OurTeamPage = () => {
  const navigate = useNavigate();

  const backToAboutPage = () => {
    navigate("/about");
  };
  return (
    <>
      <Navbar />
      <Container maxWidth="xl">
        <Box sx={{ padding: { md: "50px", xs: "25px" } }}>
          <TextIconButton Icon={WestIcon} onClick={backToAboutPage}>
            Back to About us
          </TextIconButton>

          <Stack sx={{ mt: { md: "45px", xs: "25px" } }}>
            <Typography
              sx={{
                fontSize: { md: "32px", xs: "24px" },
                color: (theme) => theme.palette.cognitiveGray.main,
                fontWeight: 300,
                fontFamily: "Archivo",
                lineHeight: "22.85px",
                marginBottom: { md: "12px", xs: "8px" },
              }}>
              Our Team
            </Typography>
            <Typography
              sx={{
                fontSize: { md: "18px", xs: "12px" },
                color: (theme) => theme.palette.cognitiveGray.main,
                fontWeight: 100,
                fontFamily: "Archivo",
                lineHeight: "22.85px",
                marginBottom: "12px",
              }}>
              {fullTeamList.length} items
            </Typography>
          </Stack>

          <Box sx={{ marginTop: { md: "50px", xs: "25px" } }}>
            <Grid container spacing={3} direction="row" display="flex">
              {fullTeamList.map((team, index) => (
                <Grid item xs={6} md={3} key={team.id}>
                  <Box sx={{ marginBottom: { md: "15px", xs: "8px" } }}>
                    <img src={team.image} alt="" style={{ width: "100%" }} />
                  </Box>

                  <Typography
                    sx={{
                      fontSize: { md: "21px", xs: "16px" },
                      color: "black",
                      fontWeight: 300,
                      fontFamily: "Archivo",
                      lineHeight: { md: "22.85px", xs: "16px" },
                      marginBottom: "8px",
                    }}>
                    {team.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { md: "18px", xs: "12px" },
                      color: "black",
                      fontWeight: 100,
                      fontFamily: "Archivo",
                      lineHeight: { md: "22.85px", xs: "12px" },
                      marginBottom: "18px",
                      width: "80%",
                    }}>
                    {team.role}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default OurTeamPage;
