import { styled } from "@mui/material";

export const ProductBox = styled("section")(({ theme }) => ({
  "& .header__section": {
    background: "#ffffff",
    padding: "4.5rem 0 10rem",
    position: "relative",
    height: "570px",
    overflow: "hidden",

    "& .hero__image": {
      width: "100%",
    },

    "& .title": {
      fontSize: "3.4rem",
      marginRight: "5rem",
      color: "#006AFF",
      fontWeight: 400,
      padding: "2rem 0",
    },

    "@media (max-width:1199.9px)": {
      "& .title": {
        fontSize: "3rem",
        marginRight: "0",
      },
    },

    "@media (max-width:767.9px)": {
      height: "100%",

      "& .title": {
        fontSize: "2.5rem",
        margin: "0 0 4rem 0",
      },
    },
  },

  "& .solution__section": {
    padding: "0 0 7rem",

    "& .text__holder": {
      marginTop: "4rem",
      width: "70%",

      "& .section__title": {
        color: "#006AFF",
      },

      "& .section__details": {
        color: "#424342",
      },
    },

    "@media (max-width:767.9px)": {
      "& .text__holder": {
        marginTop: "2rem",
        width: "100%",
        textAlign: "center",
      },
    },
  },

  "& .solution__section .header__container, .for__edu__section .header__container":
    {
      padding: "3rem 0 6rem",
      textAlign: "center",
      borderTop: "1px solid #CACCCD",

      "& .title": {
        fontSize: "3rem",
        fontWeight: 300,
        color: "#006AFF",
      },

      "& .details": {
        color: "#606060",
        fontWeight: 100,
        fontSize: "2.25rem",
      },

      "@media (max-width:767.9px)": {
        "& .title": {
          fontSize: "2.10rem",
        },

        "& .details": {
          fontSize: "1.5rem",
        },
      },
    },

  "& .payment__section": {
    padding: "7rem 0",
    background: "#E5F6FF",
    position: "relative",

    "& .img__bg": {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      height: "100%",
      width: "400px",

      "@media (min-width:2299.9px)": {
        width: "700px",
        // right: "20%",
      },
    },

    "& .section__title": {
      color: "#006AFF",
    },

    "& .paycurra__section": {
      width: "50%",
      margin: "7rem 0 0",

      "& h6": {
        fontSize: "2.5rem",
        fontWeight: 300,
        marginBottom: "1.5rem",
        color: "#006AFF",
      },

      "& p": {
        fontSize: "1.25rem",
        fontWeight: 100,
        marginBottom: "1.25rem",
        color: "#424342",
      },
    },

    "@media (max-width:767.9px)": {
      "& .img__bg": {
        display: "none",
      },

      "& .paycurra__section": {
        width: "100%",
        margin: "4rem 0 0",

        "& h6": {
          fontSize: "1.75rem",
          marginBottom: "1.15rem",
        },

        "& p": {
          fontSize: "1rem",
          marginBottom: "1rem",
        },
      },
    },
  },

  "& .for__edu__section": {
    padding: "7rem 0 3rem",

    "& .edu__banner": {
      maxHeight: "600px",
      width: "100%",
    },

    "& .header__container": {
      border: "none",

      "& .details": {
        width: "80%",
        margin: "0 auto",
      },
    },

    "& .scalability__text": {
      width: "80%",

      "& h6": {
        fontSize: "2.5rem",
        fontWeight: 300,
        color: "#006AFF",
      },

      "& p": {
        fontSize: "1.15rem",
        fontWeight: 100,
        color: "#424342",
      },
    },

    "@media (max-width:767.9px)": {
      padding: "3rem 0 1rem",

      "& .edu__banner": {
        maxHeight: "600px",
        width: "100%",
      },

      "& .header__container": {
        border: "none",

        "& .details": {
          width: "100%",
          margin: "0 auto",
        },
      },

      "& .scalability__text": {
        width: "100%",
        textAlign: "center",
        marginBottom: "2.5rem",

        "& h6": {
          fontSize: "2rem",
        },

        "& p": {
          fontSize: "1.05rem",
        },
      },
    },
  },
}));
