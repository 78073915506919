import ourTeami from "../Assets/images/ourTeami.png";
import ourTeamii from "../Assets/images/ourTeamii.png";
import ourTeamiii from "../Assets/images/ourTeamiii.png";
import ourTeamiv from "../Assets/images/ourTeamiv.png";

import blogi from "../Assets/images/featuredStoriesi.png";
import blogii from "../Assets/images/featuredStoriesii.png";

import otherStoriesi from "../Assets/images/otherStoriesi.png";
import otherStoriesii from "../Assets/images/otherStoriesii.png";

import allStoriesi from "../Assets/images/allStoriesi.png";
import allStoriesii from "../Assets/images/allStoriesii.png";

export const sectionBDetails = [
  {
    id: 1,
    title: "$6 billion addressable markets",
    content:
      "Bringing financial inclusion to the EMEA markets undergoing exponential growth- wit",
  },
  {
    id: 2,
    title: "200 million+ online payment transactions",
    content:
      "Our payment solutions provide repeat purchases and loyalty to businesses and customers",
  },
  {
    id: 3,
    title: "Promoting collaboration in the learning sectors",
    content:
      "Our solutions improve collaboration and drive up quality education and meaningful impact to 5 Million students and professionals",
  },
  {
    id: 4,
    title: "Financial Inclusion",
    content:
      "Introducing different forms of payments to reduce friction in payment processes. Helping businesses gain insights from their customers through loyalty programs",
  },
];

export const teamData = [
  {
    id: 1,
    name: "Adebola Williams",
    role: "Founder, Chairman and Chief Executive Officer",
  },
  {
    id: 2,
    name: "Adebola Williams",
    role: "Chief Business Officer",
  },
  {
    id: 3,
    name: "Adebola Williams",
    role: "Chief Product Officer",
  },
  {
    id: 4,
    name: "Adebola Williams",
    role: "Chief Technology Officer",
  },
];

export const fullTeamList = [
  {
    id: 1,
    name: "Adebola Williams",
    role: "Founder, Chairman and Chief Executive Officer",
    image: ourTeami,
  },
  {
    id: 2,
    name: "Adebola Williams",
    role: "Chief Business Officer",
    image: ourTeamii,
  },
  {
    id: 3,
    name: "Adebola Williams",
    role: "Chief Product Officer",
    image: ourTeamiii,
  },
  {
    id: 4,
    name: "Adebola Williams",
    role: "Chief Technology Officer",
    image: ourTeamiv,
  },
  {
    id: 5,
    name: "Adebola Williams",
    role: "Founder, Chairman and Chief Executive Officer",
    image: ourTeami,
  },
  {
    id: 6,
    name: "Adebola Williams",
    role: "Chief Business Officer",
    image: ourTeamii,
  },
  {
    id: 7,
    name: "Adebola Williams",
    role: "Chief Product Officer",
    image: ourTeamiii,
  },
  {
    id: 8,
    name: "Adebola Williams",
    role: "Chief Technology Officer",
    image: ourTeamiv,
  },
  {
    id: 9,
    name: "Adebola Williams",
    role: "Founder, Chairman and Chief Executive Officer",
    image: ourTeami,
  },
  {
    id: 10,
    name: "Adebola Williams",
    role: "Chief Business Officer",
    image: ourTeamii,
  },
  {
    id: 11,
    name: "Adebola Williams",
    role: "Chief Product Officer",
    image: ourTeamiii,
  },
  {
    id: 12,
    name: "Adebola Williams",
    role: "Chief Technology Officer",
    image: ourTeamiv,
  },
];

export const featuredStories = [
  {
    id: 1,
    title: "Sandy is set and readyto take the world by storm",
    description:
      "As Microsoft Gold Partners we have privileged access to advanced support from Microsoft Business Solutions. Our engineers",
    image: blogi,
  },
  {
    id: 2,
    title: "Cognitive is set and ready to take the world by storm",
    description:
      "As Microsoft Gold Partners we have privileged access to advanced support from Microsoft Business Solutions. Our engineers",
    image: blogii,
  },
];

export const otherStories = [
  {
    id: 1,
    title: "How a committed team brought wildfire boundary maps to life",
    image: otherStoriesi,
    length: "3-MINUTE READ",
  },
  {
    id: 2,
    title: "How a committed team brought wildfire boundary maps to life",
    image: otherStoriesii,
    length: "5-MINUTE READ",
  },
  {
    id: 3,
    title: "How a committed team brought wildfire boundary maps to life",
    image: otherStoriesii,
    length: "5-MINUTE READ",
  },
  {
    id: 4,
    title: "How a committed team brought wildfire boundary maps to life",
    image: otherStoriesi,
    length: "3-MINUTE READ",
  },
];

export const allStoriesList = [
  {
    id: 1,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesi,
    length: "3-MINUTE READ",
  },
  {
    id: 2,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesi,
    length: "3-MINUTE READ",
  },
  {
    id: 3,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesi,
    length: "3-MINUTE READ",
  },
  {
    id: 4,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesi,
    length: "3-MINUTE READ",
  },
  {
    id: 5,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesii,
    length: "3-MINUTE READ",
  },
  {
    id: 6,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesii,
    length: "3-MINUTE READ",
  },
  {
    id: 7,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesii,
    length: "3-MINUTE READ",
  },
  {
    id: 8,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesii,
    length: "3-MINUTE READ",
  },
  {
    id: 9,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesi,
    length: "3-MINUTE READ",
  },
  {
    id: 10,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesi,
    length: "3-MINUTE READ",
  },

  {
    id: 11,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesi,
    length: "3-MINUTE READ",
  },
  {
    id: 12,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesi,
    length: "3-MINUTE READ",
  },
  {
    id: 13,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesi,
    length: "3-MINUTE READ",
  },

  {
    id: 14,
    title: "How a committed team brought wildfire boundary maps to life",
    image: allStoriesi,
    length: "3-MINUTE READ",
  },
];
