import { Button } from "@mui/material";
import React from "react";

export const OutlinedButton = ({ children, onClick, disabled, href }) => {
  return (
    <Button
      variant="outlined"
      href={href}
      disabled={disabled}
      onClick={onClick}
      color="cognitiveBlack"
      disableRipple
      sx={{
        padding: "1rem 2.5rem",
        borderWidth: "1.5px",
        borderRadius: 0,
        borderColor: (theme) => theme.palette.primary.main,
        color: (theme) => theme.palette.primary.main,
        fontSize: "16px",
        fontWeight: 500,
        textTransform: "capitalize",
        transition: "0.3s all ease-in-out",
        "&:hover": {
          borderWidth: "1.5px",
          transform: "scale(1.02)",
          borderColor: (theme) => theme.palette.primary.main,
          background: 'transparent'
        },
      }}
    >
      {children}
    </Button>
  );
};

export const GrayOutlinedButton = ({ children, onClick, disabled, href }) => {
  return (
    <Button
      variant="outlined"
      href={href}
      disabled={disabled}
      onClick={onClick}
      color="cognitiveBlack"
      disableRipple
      sx={{
        padding: "1rem 2.5rem",
        borderWidth: "1.5px",
        borderRadius: 0,
        borderColor: (theme) => theme.palette.cognitiveGray.main,
        color: (theme) => theme.palette.cognitiveGray.main,
        fontSize: "16px",
        fontWeight: 500,
        textTransform: "capitalize",
        "&:hover": {
          borderWidth: "2px",
          borderColor: (theme) => theme.palette.cognitiveGray.main,
        },
      }}
    >
      {children}
    </Button>
  );
};

export const SolidButton = ({ children, onClick, disabled, href, colored }) => {
  return (
    <Button
      variant="contained"
      href={href}
      disabled={disabled}
      onClick={onClick}
      color="cognitiveBlack"
      disableRipple
      sx={{
        background: colored
          ? (theme) => theme.palette.primary.main
          : "transparent",
        borderRadius: 0,
        borderColor: colored
          ? (theme) => theme.palette.primary.main
          : (theme) => theme.palette.cognitiveGray.main,
        color: colored ? "white" : (theme) => theme.palette.cognitiveGray.main,
        fontSize: "16px",
        fontWeight: 500,
        textTransform: "capitalize",
        padding: "1rem 2.5rem",
        transition: "0.3s all ease-in-out",
        "&:hover": {
          background: "#006affd1",
          transform: "scale(1.02)",
        },
      }}
    >
      {children}
    </Button>
  );
};

export const TextIconButton = ({ Icon, children, onClick, color }) => {
  return (
    <Button
      variant="text"
      startIcon={<Icon />}
      onClick={onClick}
      disableRipple
      sx={{
        color: color ? color : (theme) => theme.palette.cognitiveGray.main,
        fontSize: { md: "21px", xs: "14px" },
        fontWeight: 500,
        textTransform: "capitalize",
        padding: "1rem 2.5rem",
        ".MuiButton-startIcon": {
          border: "1px solid",
          borderRadius: "100%",
          padding: "4px",
        },
      }}
    >
      {children}
    </Button>
  );
};
