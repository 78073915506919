import { styled } from "@mui/material";

export const ContactBox = styled("main")(({ theme }) => ({
  "& .header__section": {
    padding: "4.5rem 0 7.5rem",
    position: "relative",

    "& .title": {
      fontSize: "3rem",
      fontWeight: 400,
      marginBottom: "1rem",
    },

    "& .sub__title": {
      fontSize: "2rem",
      fontWeight: 300,
    },

    "& .details": {
      fontSize: "1.5rem",
      margin: "2.5rem 0rem 1rem",
      color: "#424342",
      fontWeight: 200,
      width: "80%",
    },

    "@media (max-width:767.9px)": {
      "& .title": {
        fontSize: "2.35rem",
        marginBottom: "1rem",
      },

      "& .sub__title": {
        fontSize: "1.5rem",
        fontWeight: 300,
      },

      "& .details": {
        fontSize: "1.05rem",
        margin: "2rem 0rem",
        width: "95%",
      },
    },
  },

  "& .link": {
    color: "rgb(0, 106, 255)",
    fontSize: "1.25rem",
    fontWeight: 100,
    textDecoration: "none",
    transition: "0.3s all ease-in-out",

    "&:hover": {
      fontWeight: 500,
    },
  },
}));
