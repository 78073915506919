import { Box, Container, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import cognitiveLabs from "../../Assets/images/clab.svg";
import { FooterLinkHeader, FooterSocialHeader } from "../Typograyphy";
import {
  FaInstagram,
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";
import { scroller } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // console.log(pathname);

  const handleClick = (sectionId) => {
    if (pathname === "/") {
      navigate(pathname, { state: sectionId });
      scroller.scrollTo(sectionId, {
        duration: 500,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: 0, // Adjust as needed
      });
    } else {
      navigate("/", { state: sectionId });
    }
  };

  return (
    <footer className={styles.footer__container}>
      <div className="container">
        <div className="mb-4">
          <img
            src={cognitiveLabs}
            alt="cognitive labs logo"
            width="200px"
            className={styles.logo__img}
          />
        </div>
        <div className="row">
          <div className="col-md-8">
            <p className={styles.footer__note}>
              Products for personalised commerce that empower people and
              organisations to thrive.
            </p>
          </div>
          <div className="col-md-2 col-6">
            <FooterLinkHeader>Products</FooterLinkHeader>
            <ul className={`${styles.links__group} list-group`}>
              <li>
                <a
                  href={`https://www.paycurra.com`}
                  target="_blank"
                  className={`${styles.footerLinkItem}`}
                >
                  Paycurra
                </a>
              </li>
            </ul>

            <div className="mt-5">
              <FooterSocialHeader>Connect with us</FooterSocialHeader>

              <div className={`${styles.socialIcon}`}>
                <a
                  href="https://www.instagram.com/cognitivelabs_/"
                  target={"_blank"}
                >
                  <FaInstagram />
                </a>
                <a href="https://fb.com/cognitivelabsinc" target={"_blank"}>
                  <FaFacebookF />
                </a>
                <a
                  href="https://www.linkedin.com/company/cognitivelabs-co"
                  target={"_blank"}
                >
                  <FaLinkedinIn />
                </a>
                <a href="https://twitter.com/cognitivelabsco" target={"_blank"}>
                  <FaTwitter />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-6">
            <FooterLinkHeader>Company</FooterLinkHeader>
            <ul className={`${styles.links__group} list-group`}>
              <li
                className={`${styles.footerLinkItem}`}
                onClick={() => handleClick("#mission")}
              >
                {/* <Link to={`/about`} className={`${styles.footerLinkItem}`}> */}
                Mission
                {/* </Link> */}
              </li>
              <li
                className={`${styles.footerLinkItem}`}
                onClick={() => handleClick("#solutions")}
              >
                {/* <Link to={`/products`} className={`${styles.footerLinkItem}`}> */}
                Solutions
                {/* </Link> */}
              </li>
              <li className="">
                <Link to={`/contact-us`} className={`${styles.footerLinkItem}`}>
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
