import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { NavLink, useNavigate, useLocation, Link } from "react-router-dom";
import cognitiveLabs from "../../Assets/images/clab.svg";
import { Divider, Stack } from "@mui/material";
import { Search } from "@mui/icons-material";
import { NavbarBox } from "./navbar.styles";
import { scroller } from "react-scroll";

const pages = [
  {
    id: 1,
    name: "Mission",
    path: "#mission",
  },
  {
    id: 2,
    name: "Solutions",
    path: "#solutions",
  },
  {
    id: 3,
    name: "Opportunity",
    path: "#opportunity",
  },
  {
    id: 4,
    name: "Contact Us",
    path: "contact",
  },
];

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [selectedSection, setSelectedSection] = React.useState("");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClick = (sectionId) => {
    if (location.pathname === "/") {
      navigate("/", { state: sectionId });
      scroller.scrollTo(sectionId, {
        duration: 500,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: 0, // Adjust as needed
      });
    } else {
      navigate("/", { state: sectionId });
    }
  };

  return (
    <NavbarBox>
      <div className="container">
        <Toolbar disableGutters>
          <Link to="/" className="logo__container me-5">
            <img
              src={cognitiveLabs}
              alt="cognitive labs logo"
              className="img-fluid"
            />
          </Link>

          <Box sx={{ display: { xs: "flex", md: "none", marginLeft: "auto" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              disableRipple
              onClick={handleOpenNavMenu}
              sx={{ pr: 0 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
                width: "100%",
              }}
            >
              {pages.map((page) => (
                <Box key={page.id} sx={{ px: 3, py: 1 }}>
                  <Button
                    key={page.id}
                    onClick={() => {
                      handleClick(page.path);
                      handleCloseNavMenu();
                    }}
                    disableRipple
                    sx={{
                      color: (theme) => theme.palette.cognitiveGray.main,
                      display: "block",
                      textTransform: "capitalize",
                      fontWeight: 100,
                      fontFamily: "Archivo",
                      fontSize: "18px",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    className={({ isActive }) =>
                      isActive ? "activeClassName" : undefined
                    }
                  >
                    <div
                      className={`${
                        location.state === page.path ? "active__link" : ""
                      } link`}
                    >
                      {page.name}
                    </div>
                  </Button>
                </Box>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.slice(0, 3).map((page) => (
              <Button
                key={page.id}
                onClick={() => handleClick(page.path)}
                disableRipple
                sx={{
                  color: (theme) => theme.palette.cognitiveGray.main,
                  display: "block",
                  textTransform: "capitalize",
                  fontWeight: 100,
                  fontFamily: "Archivo",
                  fontSize: "18px",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                className={({ isActive }) =>
                  isActive ? "activeClassName" : undefined
                }
              >
                <div
                  className={`${
                    location.state === page.path ? "active__link" : ""
                  } link`}
                >
                  {page.name}
                </div>
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <Stack direction="row" alignItems="center">
              <Button
                component={NavLink}
                to={"/contact-us"}
                disableRipple
                sx={{
                  color: (theme) => theme.palette.cognitiveGray.main,
                  display: "block",
                  textTransform: "capitalize",
                  fontWeight: 100,
                  fontFamily: "Archivo",
                  fontSize: "18px",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                className={({ isActive }) =>
                  isActive ? "activeClassName" : undefined
                }
              >
                <div
                  className={`${
                    location.pathname === "/contact-us" ? "active__link" : ""
                  } link`}
                >
                  Contact Us
                </div>
              </Button>
              {/* <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                sx={{ mx: "24px" }}
              />
              <IconButton disableRipple>
                <Search sx={{ color: "black" }} />
              </IconButton> */}
            </Stack>
          </Box>
        </Toolbar>
      </div>
    </NavbarBox>
  );
}
export default Navbar;
