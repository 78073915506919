import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { TextIconButton } from "../../Components/Buttons";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import { allStoriesList, fullTeamList } from "../../Lib/data";
import Footer from "../../Components/Footer";

const AllStoriesPage = () => {
  const navigate = useNavigate();

  const backToAboutPage = () => {
    navigate("/stories");
  };
  return (
    <>
      <Navbar />
      <Container maxWidth="xl">
        <Box sx={{ padding: { md: "50px", xs: "25px" } }}>
          <TextIconButton Icon={WestIcon} onClick={backToAboutPage}>
            Back to Stories
          </TextIconButton>

          <Stack sx={{ mt: { md: "45px", xs: "25px" } }}>
            <Typography
              sx={{
                fontSize: { md: "32px", xs: "24px" },
                color: (theme) => theme.palette.cognitiveGray.main,
                fontWeight: 300,
                fontFamily: "Archivo",
                lineHeight: "22.85px",
                marginBottom: "12px",
                textAlign: { md: "center", xs: "left" },
              }}>
              All Stories
            </Typography>
          </Stack>

          <Box sx={{ marginTop: { md: "50px", xs: "25px" } }}>
            <Grid
              container
              spacing={{ md: 3, xs: 2 }}
              direction="row"
              display="flex">
              {allStoriesList.map((story, index) => (
                <Grid item xs={6} md={3} key={story.id}>
                  <Typography
                    sx={{
                      fontSize: { md: "21px", xs: "12px" },
                      color: "#424342",
                      fontFamily: "Archivo",
                      fontWeight: 300,
                      mb: { md: "20px", xs: "12px" },
                      textAlign: { md: "center", xs: "left" },
                      mx: "auto",
                    }}>
                    {story.title}
                  </Typography>
                  <Box
                    sx={{
                      width: { md: "80%", xs: "90%" },
                      mx: { md: "auto" },
                    }}>
                    <img
                      src={story.image}
                      alt=""
                      style={{
                        width: "100%",
                        marginBottom: { md: "15px", xs: "10px" },
                        padding: { md: "0px 20px", xs: "0px 10px" },
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { md: "12px", xs: "10px" },
                      color: "#424342",
                      fontFamily: "Hind",
                      fontWeight: 400,
                      mb: { md: "35px", xs: "20px" },
                      textAlign: "center",
                      width: "75%",
                      mx: "auto",
                    }}>
                    {story.length}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default AllStoriesPage;
