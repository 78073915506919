import React from "react";
import Navbar from "../../Components/Navbar";
import {
  Box,
  Container,
  Grid,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import Footer from "../../Components/Footer";
import { LargeText } from "../../Components/Typograyphy";
import { NormalText } from "../../Components/Typograyphy";
import { LightText } from "../../Components/Typograyphy";
import { SmallText } from "../../Components/Typograyphy";
import { SolidButton } from "../../Components/Buttons";
import { Link } from "react-router-dom";
import { ContactBox } from "./contact.styles";

const ContactPage = () => {
  // const handleContactUs = () => {
  //   window.location = "mailto:partnerships@cognitivelabs.co";
  // };

  return (
    <>
      <Navbar />
      <ContactBox>
        <header className="header__section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 py-lg-5">
                <h3 className="title">
                  Hello, how may we be of service to you today?
                </h3>
                {/* <h6 className="sub__title">Support &amp; Partnerships</h6> */}
                <p className="details">
                  Have any questions about what we do or interested in exploring
                  potential partnerships?
                </p>

                <a
                  className="link"
                  href="mailto:partnerships@cognitivelabs.co"
                  target="_blank"
                >
                  partnerships@cognitivelabs.co
                </a>

                {/* <SolidButton onClick={handleContactUs} colored>
                  Get in touch
                </SolidButton> */}
              </div>
            </div>
          </div>
        </header>

        <section className="mb-5 pb-lg-4">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-5">
                <h4 className="section__title">Cognitive Labs HQ</h4>
                <p className="section__details me-lg-5 me-2 pe-lg-3">
                  England,United Kingdom.
                </p>
              </div>
              <div className="col-md-6 mb-5">
                <h4 className="section__title">Careers at Cognitive</h4>
                <p className="section__details me-lg-5 me-2 pe-lg-3">
                  We seek talented analyst, software engineers, data engineers,
                  and architects.
                </p>
                <a
                  className="link"
                  href="mailto:careers@cognitivelabs.co"
                  target="_blank"
                >
                  careers@cognitivelabs.co
                </a>
              </div>
              {/* <div className="col-md-6 mb-5">
                <h4 className="section__title">Press</h4>
                <p className="section__details me-lg-5 me-2 pe-lg-3">
                  Want to know more about what we do?
                </p>
                <a className="link">info@cognitivelabs.co</a>
              </div>
              <div className="col-md-6 mb-5">
                <h4 className="section__title">Book a demo</h4>
                <p className="section__details me-lg-5 me-2 pe-lg-3">
                  Want a product demonstration? Reach on here to our team.
                </p>
                <SolidButton colored> Schedule Booking</SolidButton>
              </div> */}
            </div>
          </div>
        </section>
      </ContactBox>

      <Footer />
    </>
  );
};

export default ContactPage;
