import {
  Box,
  Container,
  Grid,
  Stack,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import {
  HeaderDescription,
  LargeText,
  LightText,
  NormalText,
  SmallText,
} from "../../Components/Typograyphy";
import productHero from "../../Assets/images/producthero.png";
import productEvents from "../../Assets/images/productevents.png";

import aboutEvents from "../../Assets/images/aboutevents.png";
import aboutEventsii from "../../Assets/images/About.Hero.Section.Events.png";

import educationBanner from "../../Assets/images/CognitiveLabs.ForEducation.Section.png";
import designedForScalability from "../../Assets/images/Designed.ForScalability.Section - NoBackground.png";
import products from "../../Assets/images/product.png";
import PaymentSolutionImg from "../../Assets/images/payment_solution_bg.png";

import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import { SectionItem } from "../../Components/SectionItem";
import { sectionBDetails, teamData } from "../../Lib/data";
import { TextIconButton } from "../../Components/Buttons";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import { East } from "@mui/icons-material";
import { ProductBox } from "./product.styles";

const ProductstPage = () => {
  const navigate = useNavigate();

  const seeFullTeam = () => {
    navigate("/about/full-team");
  };
  return (
    <>
      <Navbar />
      <ProductBox>
        <header className="header__section">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-6 d-flex align-items-end">
                <h5 className="title">
                  Products that deliver scalable, secure and evolvable outcomes
                </h5>
              </div>
              <div className="col-lg-6">
                <img
                  src={productHero}
                  className="img-fluid hero__image"
                  alt="Cognitive labs products"
                />
              </div>
            </div>
          </div>
        </header>

        <section className="solution__section">
          <div className="container h-100">
            <div className="header__container">
              <h4 className="title">
                Cognitive Labs; <br /> Education Solutions
              </h4>
              <p className="details">
                Built to improve quality and standard in the learning industry.
              </p>
            </div>

            <div className="row h-100">
              <div className="col-lg-6 h-100 d-flex  lign-items-center">
                <div className="text__holder">
                  <h5 className="section__title">
                    Digitize your learning partner acquistion programs
                  </h5>
                  <p className="section__details">
                    For indepent accrediting bodies
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={productEvents}
                  alt="Cognitive labs - Digitize your learning partner acquistion programs"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="payment__section">
          <div className="container">
            <img src={PaymentSolutionImg} className="img__bg" />

            <div className="col-lg-8">
              <h5 className="section__title">
                Cognitive Labs: <br /> Payments Solutions
              </h5>
              <p className="section__details">
                Products that enhance customer retention and get deeper insights
                into customer behavior.
              </p>

              <div className="paycurra__section">
                <h6>Paycurra</h6>
                <p>
                  Payment-linked loyalty platforms that reduces friction and
                  gives customers ability to auto-earn rewards when they make
                  payments.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="for__edu__section">
          <div className="container h-100">
            <div className="header__container">
              <h4 className="title">Cognitive labs for Education</h4>
              <p className="details">
                Increase engagements with your community of learning providers
                in a way that boosts their profile.
              </p>
            </div>

            <img src={educationBanner} className="img-fluid edu__banner" />

            <div className="row py-5 my-5 h-100">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="scalability__text">
                  <h6>Designed for scalability</h6>
                  <p>
                    Improve collaboration and connection with your community of
                    schools and colleges to deliver required standards and
                    quality assurance programs.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <img
                  src={designedForScalability}
                  alt="Cognitive Labs - Designed for scalability"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
      </ProductBox>
      <Footer />
    </>
  );
};

export default ProductstPage;
