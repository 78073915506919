import React from "react";
import Navbar from "../../Components/Navbar";
import Footer from "../../Components/Footer";
import hero from "../../Assets/images/header-bg.jpeg";
import { HomeBox } from "./home.styles";
import POSImg from "../../Assets/images/POS.png";
import GiftBagImg from "../../Assets/images/gift-bag.jpeg";
import CardPaymentImg from "../../Assets/images/card-payment.jpeg";
import PayWithCardImg from "../../Assets/images/pay-with-card.jpeg";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import CognitiveMetaImg from "../../Assets/images/clabsindexevents.png";
import { Seo } from "../../Components/Seo";

const HomePage = () => {
  const { pathname, state } = useLocation();

  React.useEffect(() => {
    if (state) {
      scroller.scrollTo(state, {
        duration: 500,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: 0, // Adjust as needed
      });
    }
  }, [pathname]);

  return (
    <>
      <Seo
        title="Products for personalised commerce that empower people and organisations to thrive"
        description="We are  energized about solving big and meaningful problems. | Cognitive Labs"
        keywords="cognitive, labs, Software Development, IT Solutions, Technology Services, Custom Software, Web Development,Enterprise Solutions, E-commerce Solutions, IT Consulting, IT Infrastructure, Digital Transformation, Software Integration, Best, software, e-commerce, africa, england, nigeria, 2023, 2024, web, application, digital, value, adding, startup, integration"
        image={CognitiveMetaImg}
      />

      <Navbar />
      <HomeBox>
        <header className="header__section" id="#mission">
          <div className="container">
            <div className="header__container">
              <h5 className="title">
                Products for personalised commerce that empower people and
                organisations to thrive
              </h5>

              {/* <img src={hero} className="img-fluid hero__image" /> */}
              <div className="hero__image"></div>
              <p className="details">
                We build composable capabilities that help drive compelling
                outcomes — utilising the power of data and AI to solve for
                tailored experiences in frontier markets and beyond.
              </p>
            </div>
          </div>
        </header>

        <section className="solution__space" id="#solutions">
          <div className="container">
            <div className="text__holder">
              <h5 className="sub__title">SOLUTIONS SPACE</h5>
              <h3 className="title">
                E-commerce, Data, and Payments
              </h3>
            </div>
            <div className="row">
              <div className="col-md-4 d-flex align-items-center">
                <div className="solution__card">
                  <img src={POSImg} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <div className="solution__card active__card">
                  <img src={CardPaymentImg} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-center">
                <div className="solution__card">
                  <img src={GiftBagImg} className="img-fluid" />
                </div>
              </div>
            </div>

            <p className="details">
              Our solutions are value-adding— from checkout personalisation that
              increases conversion and enables more ways to pay; digitised
              cross-sell recommendations that delight and increase cart value;
              and mass customisation that delivers the most appealing rewards
              and offerings to targeted customer segments in near real-time.
            </p>
          </div>
        </section>

        <section className="market__section" id="#opportunity">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="text__holder">
                  <h3 className="title">Frontier markets and beyond.</h3>
                  <p className="details">
                    In emerging markets, the rise of fin-techs, neo-banks and
                    e-wallets has given rise to both financial inclusion; and
                    fragmentation of payment transaction data — thus reducing
                    ability for businesses to understand their customer
                    behaviour.
                    <br /> <br />
                    We are on a mission to be an end-to-end commerce, payments and
                    data technology platform that helps businesses connect the
                    dots in payments data in a way that spurs growth through
                    insightful customer engagements.
                  </p>

                  <ul className="market__list">
                    <li className="item">
                      <h5 className="name">
                        Traditional banks and financial incumbents
                      </h5>
                      <p className="value">
                        With Loyalty programmes in our launch market to grow
                        13.4% to $939 million by 2030, our payments agnostic
                        loyalty solution would enable traditional banks to
                        combat fragmentation with delightful experiences that
                        sustain customer engagement and collaborative
                        partnership offerings.
                      </p>
                    </li>
                    <li className="item">
                      <h5 className="name">Neo-banks and e-wallets</h5>
                      <p className="value">
                        With the incentive card market to grow 11% to $2.78
                        billion by 2027 in our launch market, our rewards
                        orchestration solution would help digitally-native
                        insurgents drive-up net promoter scores by offering
                        consumers personalised incentives that encourage repeat
                        transactional behaviours.
                      </p>
                    </li>
                    <li className="item">
                      <h5 className="name">Retail and e-commerce</h5>
                      <p className="value">
                        And our checkout solution for merchants seamlessly
                        integrates with your promotions and loyalty offerings to
                        incentivise your customers and enable more ways to pay.
                        Ours is a seamless checkout solution built to enable
                        accelerated growth.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="market__img">
                  <img src={PayWithCardImg} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="culture__section">
          <div className="container">
            <h4 className="title">OUR CULTURE</h4>

            <p className="details">
              We are <span>&nbsp;energized</span> about solving big and
              meaningful problems; we are<span>&nbsp;customer-focused</span> and
              leveraged on
              <span>&nbsp;diversity—</span>maximizing the power of what makes us
              different to create <span>vibrant </span> and{" "}
              <span>sustainable</span> solutions.
            </p>
          </div>
        </section>
      </HomeBox>
      <Footer />
    </>
  );
};

export default HomePage;
