import { CssBaseline } from "@mui/material";
import AboutPage from "./Pages/About";
import { Route, Routes } from "react-router-dom";
import ProductstPage from "./Pages/Products";
import StoriesPage from "./Pages/Stories";
import ContactPage from "./Pages/Contact";
import HomePage from "./Pages/Home";
import OurTeamPage from "./Pages/About/Team";
import AllStoriesPage from "./Pages/Stories/AllStories";

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/about/our-team" element={<OurTeamPage />} />
        <Route path="/products" element={<ProductstPage />} />
        <Route path="/stories" element={<StoriesPage />} />
        <Route path="/stories" element={<StoriesPage />} />{" "}
        <Route path="/stories/all-stories" element={<AllStoriesPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
      </Routes>
    </div>
  );
}

export default App;
