import { styled } from "@mui/material";

export const NavbarBox = styled("nav")(({ theme }) => ({
  background: "#FFFFFF",
  padding: "1.25rem 0",
  position: "relative",
  zIndex: 1,

  "& .logo__container": {
    width: "150px",
  },

  "& .menu__item": {
    color: "#424342",
    fontSize: "1rem",
    fontWeight: 100,
    padding: "0.5rem",

    "& a": {
      color: "#424342",
      textDecoration: "none",
    },
  },

  "& .link": {
    fontWeight: 400,
    padding: "0.5rem 0.85rem",
    transition: ".7 all ease",
    borderBottom: "1.5px solid transparent",
  },

  "& .active__link, .activeClassName, .link:hover": {
    color: "#006AFF",
    fontWeight: 400,
    borderBottom: "1.5px solid #006AFF",
    // animation: "moveBorder .5s linear forwards 0s",
  },
}));
