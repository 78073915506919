import { styled } from "@mui/material";

export const AboutBox = styled("section")(({ theme }) => ({
  "& .header__section": {
    background: "#ffffff",
    padding: "4.5rem 0 7.5rem",
    position: "relative",
    height: "570px",
    overflow: "hidden",

    "& .hero__image": {
      position: "absolute",
      bottom: 0,
      right: "-1.5rem",
      width: "50%",

      "@media (min-width:2000px)": {
        right: "unset",
        width: "900px",
      },

      "@media (max-width:767.9px)": {
        width: "100%",
        position: "unset",
      },
    },

    "& .title": {
      fontSize: "3.4rem",
      marginRight: "10rem",
      color: "#006AFF",
      fontWeight: 400,
      position: "relative",
      zIndex: 1,
      padding: "2rem 0",
    },

    "@media (max-width:1199.9px)": {
      "& .title": {
        fontSize: "3rem",
        marginRight: "0",
      },
    },

    "@media (max-width:767.9px)": {
      height: "100%",

      "& .title": {
        fontSize: "2.5rem",
        margin: "0 0 4rem 0",
      },
    },
  },

  "& .metric__section": {
    background: "#E5F6FF",
    padding: "7rem 0",
    overflow: "hidden",
    position: "relative",

    "& .image__holder": {
      "& img": {
        position: "absolute",
        left: "-1.5rem",
        top: "25%",
        width: "50%",
      },
    },

    "& .text__container": {
      marginRight: "3rem",

      "& .title": {
        color: "#000000",
        fontSize: "2.25rem",
        fontWeight: 100,
        marginBottom: "2.5rem",
      },

      "& .analysis__container": {
        marginBottom: "2.5rem",

        "& h5": {
          fontSize: "1.5rem",
          fontWeight: 400,
          marginBottom: "0.5rem",
          minHeight: "48px",
        },

        "& p": {
          fontSize: "1.15rem",
          fontWeight: 100,
          marginBottom: "0",
        },
      },
    },

    "@media (min-width:2000px)": {
      "& .image__holder": {
        "& img": {
          position: "relative",
          marginTop: "5rem",
          width: "100%",
        },
      },
    },

    "@media (max-width:767.9px)": {
      padding: "5rem 0",

      "& .image__holder": {
        "& img": {
          display: "none",
        },
      },

      "& .text__container": {
        marginRight: "0",
        position: "relative",
        zIndex: 1,

        "& .title": {
          fontSize: "2rem",
          marginBottom: "2.5rem",
        },

        "& .analysis__container": {
          "& h5": {
            fontSize: "1.25rem",
            minHeight: "unset",
          },

          "& p": {
            fontSize: "1rem",
          },
        },
      },
    },
  },

  "& .culture__section": {
    padding: "7rem 0",

    "& .title": {
      fontWeight: 300,
      fontSize: "1.25rem",
      textTransform: "uppercase",
      marginBottom: "2rem",
      color: "#424342",
    },

    "& .details": {
      fontWeight: 100,
      fontSize: "2.25rem",
      color: "#000000",
      width: "90%",

      "& span": {
        fontWeight: 400,
        color: "#006AFF",
      },
    },

    "@media (max-width:767.9px)": {
      padding: "5rem 0",

      "& .title": {
        fontSize: "1.15rem",
      },

      "& .details": {
        fontSize: "1.5rem",
        width: "100%",
      },
    },
  },
}));
